<template>
  <a-spin :spinning="spinning">
    <div class="">
      <div class="tk_table">
        <ele-table ref="rt" :columns="columns" :table-data="tableData" :total-items="totalItems" :is-full="true"
          :actionsType="actionsType" :current-page="pageNumber" :hide-row-selection="false"
          @emitOnPageChange="updatePageChange" @emitShowSizeChange="updateShowSizeChange">
          <!-- 按钮 -->
          <a-row :gutter="24">
            <a-col :span="4">
              <a-input v-model="searchDto.Filter" :placeholder="l('任务编码')" />
            </a-col>
            <a-col :span="5">
              <a-button type="primary" @click="getData" v-if="isGranted('Pages.ExportTask.Query')">
                <a-icon type="search" /> {{ l("搜索") }}
              </a-button>
              <a-button @click="clearFilterAndRefresh">
                {{ l("重置") }}
              </a-button>
            </a-col>
          </a-row>
        </ele-table>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import EleTable from "@/components/ele-table";
import moment from "moment";
import { fileDownloadService } from "@/shared/utils";

export default {
  mixins: [AppComponentBase],
  name: "ExportTaskIndex",
  components: { EleTable },
  data: function () {
    return {
      columns: [],
      tableData: [],
      actionsType: null,
      totalItems: 0,
      pageNumber: 0,
      searchDto: {
        Filter: '',
        SkipCount: 0,
        MaxResultCount: 50
      },
      spinning: false,
      pagerange: [],
    };
  },
  methods: {
    // 分页
    updatePageChange (newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.searchDto.SkipCount = (page - 1) * this.searchDto.MaxResultCount;
      this.getData();
    },
    updateShowSizeChange (newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.searchDto.MaxResultCount = size;
      this.getData();
    },
    clearFilterAndRefresh () {
      this.searchDto.Filter = '';
      this.pageNumber = 1;
      this.searchDto.SkipCount = 0;
      this.getData();
    },
    getData () {
      this.$api.request({
        url: this.$apiUrl + "/api/services/app/ExportTask/GetPages",
        method: "POST",
        data: {
          TaskCode: this.searchDto.Filter,
          SkipCount: this.searchDto.SkipCount,
          MaxResultCount: this.searchDto.MaxResultCount
        },
        headers: { "Content-Type": "application/json-patch+json" },
      }).then((res) => {
        if (res.status === 200) {
          let data = res.data;
          this.totalItems = data.totalCount;
          this.totalPages = Math.ceil(
            data.totalCount / this.searchDto.MaxResultCount
          );
          this.pagerange = [
            (this.pageNumber - 1) * this.searchDto.MaxResultCount + 1,
            this.pageNumber * this.searchDto.MaxResultCount,
          ];
          this.tableData = data.items.map(item => {
            return {
              ...item,
              taskResultStr: this.getTaskResult(item.taskResult),
              creationTimeStr: `${moment(item.creationTime).format("YYYY-MM-DD")}`,
              sourceStr: this.getTaskSource(item.source),
              startTimeStr: `${item.startTime ? item.startTime.replace("T", " ") : ""}`,
              endTimeStr: `${item.endTime ? item.endTime.replace("T", " ") : ""}`
              //taskResultStr: `${moment(item.startTime).format("YYYY-MM-DD")}~${moment(item.endTime).format("YYYY-MM-DD")}`
            }
          });
        } else {
          abp.message.warn(this.l("DefaultErrorMessage"));
        }
      });
    },
    initTable () {
      const that = this;
      this.columns = [
        {
          title: "任务名称",
          dataIndex: "taskName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "taskName" },
        },
        {
          title: "任务编码",
          dataIndex: "taskCode",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "taskCode" },
        },
        {
          title: "导出结果",
          dataIndex: "taskResultStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "taskResultStr" },
        },
        {
          title: "创建人",
          dataIndex: "creator",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "creator" },
        },
        {
          title: "创建时间",
          dataIndex: "creationTimeStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "creationTimeStr" },
        },
        {
          title: "来源",
          dataIndex: "sourceStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "sourceStr" },
        },
        {
          title: "开始时间",
          dataIndex: "startTimeStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "startTimeStr" },
        },
        {
          title: "结束时间",
          dataIndex: "endTimeStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "endTimeStr" },
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "actions" },
        }
      ];
      let _this = this;
      this.actionsType = {
        type: "export-task",
        isShow: true,
        fns: {
          detailList: [
            {
              granted: this.isGranted("Pages.ExportTask.Query"),
              name: this.l("下载"),
              icon: "download",
              fn: (data) => {
                if (data.downloadUrl && data.downloadUrl.indexOf('http') === 0) {
                  window.location.href = data.downloadUrl;
                } else if (data.downloadUrl) {
                  const url = `${this.$apiUrl}/api/services/app/ExportTask/DownloadFile?id=${data.id}&tenantId=${data.tenantId}`;
                  //window.location.href = `${this.$apiUrl}/api/services/app/ExportTask/DownloadFile?id=${data.id}&tenantId=${data.tenantId}`;
                  window.open(url);
                  // that.$api.request({
                  //   url: this.$apiUrl + `/api/services/app/ExportTask/DownloadFile?id=${data.id}`,
                  //   method: "GET",
                  //   headers: { "Content-Type": "application/json-patch+json" },
                  // }).then(res => {
                  //   console.warn('res=', res);
                  //   if (res.status !== 200) {
                  //     this.$message.error(res.statusText);
                  //     return;
                  //   }
                  //   fileDownloadService.downloadTempFile(res.data);
                  // });
                }
              },
              EnableFunc (row) {
                console.info(row.taskResult !== 2);
                return row.taskResult !== 2;
              }
            },
          ]
        }
      }
    },
    getTaskResult (result) {
      switch (result) {
        case 0:
          return "未开始";
        case 1:
          return "处理中";
        case 2:
          return "成功";
        case 3:
          return "失败";
        default:
          return "";
      }
    },
    getTaskSource (source) {
      switch (source) {
        case 1:
          return "项目人员";
        case 2:
          return "人员管理";
        case 3:
          return "发放明细";
        case 4:
          return "发放汇总";
        default:
          return "";
      }
    }
  },
  created () {
    this.initTable();
    this.getData();
  },
};
</script>
